import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import SplitType from 'split-type';

gsap.registerPlugin(TextPlugin);

const loadHeroAnimation = (hero: HTMLDivElement | null) => {
    if (!hero) return;

    const heroText = new SplitType(hero, { types: 'chars' });
    const heroChars = heroText.chars;

    hero.classList.remove('hidden');

    gsap.fromTo(
        heroChars,
        {
            y: 100,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 2,
            ease: 'power4.out',
            delay: 0.5,
            onComplete: function() {
                startTextAnimation();
                animateCursor();
            },
        }
    );
};

const texts = [' full-stack developer', 'n enthusiastic musician', ' coffee lover'];

function startTextAnimation() {
    let hero_mtl = gsap.timeline({ repeat: -1 });

    texts.forEach(text => {
        let hero_tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1.5 });
        hero_tl.to('.hero__textAnimation', { duration: 2, text: text });
        hero_mtl.add(hero_tl);
    });
}

function animateCursor() {
    const cursor = document.querySelector('.underline'); // Das Element auswählen
    if (cursor) {
        gsap.to(cursor, {
            opacity: 0,
            ease: "power2.inOut",
            repeat: -1,
            yoyo: true,
            duration: 0.5,
        });
    } else {
        console.warn('Cursor element not found.');
    }
}

function Hero() {
    const heroRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        loadHeroAnimation(heroRef.current);
    }, []);

    return (
        <section id="home" className="hero hidden" ref={heroRef}>
            <div className="hero--container">
                <strong>Hi, my name is</strong>
                <h1>Adrian <span>Bakalarz</span></h1>
                <h3>
                    and I'm a<span className="hero__textAnimation"></span>
                    <span className="underline" style={{ opacity: 1 }}>_</span>
                </h3>
            </div>
        </section>
    );
}

export default Hero;
