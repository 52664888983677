import React from 'react';
import { FaGithub, FaLinkedin, FaDiscord } from 'react-icons/fa';

function Footer() {
    return (
        <footer id="end">
            <div className="curve">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"
                    ></path>
                </svg>
            </div>
            <div className="bubble-container">
                <div className="footer-container">
                    <div className="social">
                        <div id="github" className="social-icon">
                            <a href="https://github.com/MrBrut-glitch" target="_blank" rel="noopener noreferrer">
                                <FaGithub />
                            </a>
                        </div>
                        <div id="linkedin" className="social-icon">
                            <a href="https://www.linkedin.com/in/adrian-bakalarz-133a4b27b/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>
                        </div>
                        <div id="discord" className="social-icon">
                            <a href="https://discordapp.com/users/719610022937493566" target="_blank" rel="noopener noreferrer">
                                <FaDiscord />
                            </a>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>&copy; 2024 | Made with &lt;3 from Austria</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
