import React, {useEffect} from 'react';

function About() {

    useEffect(() => {
        const fancyText = document.querySelectorAll(".highlight-word");
        fancyText.forEach(text => {
            if (!text.textContent!.includes("()")) {
                text.textContent += "()";
            }
        });
    }, []);

    return (
        <section id="about" className="about">
            <div className="section--title">
                <strong>Who I Am</strong>
                <h2>About me_</h2>
            </div>
            <p>
                I am <span className="highlightText highlight-number">age = 18</span> years old, currently in my fourth
                year as a <span className="highlightText highlight-word">student</span> at HTBLA Kaindorf a. d. Sulm. My
                passion for technology and computer science began at an early age, which led me to choose this
                institution. Over the course of these four years, I’ve had the opportunity to deepen my knowledge in
                various fields, and my educational <span className="highlightText highlight-word">journey</span> is far
                from over. However, school alone hasn't shaped my path — my innate <span
                className="highlightText highlight-word">curiosity</span> and constant <span
                className="highlightText highlight-word">motivation</span> have played key roles in driving me forward.
                As a result, <span className="highlightText highlight-word">coding</span> has become one of my
                greatest <span className="highlightText highlight-word">passions</span>.
            </p>
            <p> I program across a broad range of areas, focusing on <span className="highlightText highlight-word">software development</span> as
                well as <span className="highlightText highlight-word">web development</span>. I have also built <span
                    className="highlightText highlight-word">mobile applications</span>. During my journey, I’ve worked
                with languages like <span className="highlightText code">&lt;/ C, Java, Typescript, Python &gt;</span>,
                and I enjoy taking on new tasks and challenges. My curiosity pushes me to learn <span
                    className="highlightText highlight-word">new things</span> constantly. I am also deeply interested
                in fields like <span className="highlightText highlight-word">data science</span>, <span
                    className="highlightText highlight-word">project management</span>, and <span
                    className="highlightText highlight-word">AI</span>. </p>
            <p>
                Outside the realm of technology, my hobbies offer a colorful contrast to my technical pursuits. <span
                className="highlightText highlight-word">Music</span> plays an essential role in my life; I am a violist
                and flutist, performing with the Grenzlandtrachtenkapelle (GTK) Mureck. More recently, I’ve added the
                piano to my repertoire, further enriching my love for <span
                className="highlightText highlight-word">music</span>. In addition, I’m an avid <span
                className="highlightText highlight-word">chess</span> enthusiast, though in the end, music always takes
                the top spot.
            </p>
            <p>
                My personal story is shaped by diverse experiences from different parts of the world. Originally
                from <span className="highlightText highlight-word">Luxembourg</span>, I spent my early years attending
                elementary school there. In 2017, my life took a <span
                className="highlightText highlight-word">significant</span> turn when my family and I moved to Austria,
                specifically to the picturesque province of Styria. Here, I completed secondary school with outstanding
                results, which opened the door for me to pursue my studies at HTBLA Kaindorf.
            </p>
        </section>
    );
}

export default About;