import React from 'react';

function Skills() {
    return (
        <section id="skills" className="skills">
            <div className="section--title">
                <strong>Mastering abilities</strong>
                <h2>Skills_</h2>
            </div>
            <div className="bubble--container">
                <div className="bubble"><p>Docker</p></div>
                <div className="bubble"><p>Java</p></div>
                <div className="bubble"><p>Network Engineering</p></div>
                <div className="bubble"><p>Git</p></div>
                <div className="bubble"><p>Azure DevOps</p></div>
                <div className="bubble"><p>Web Development</p></div>
                <div className="bubble"><p>Bash scripting</p></div>
                <div className="bubble"><p>Data Science</p></div>
                <div className="bubble"><p>Postgres / MongoDB</p></div>
                <div className="bubble"><p>Hardware Engineering</p></div>
                <div className="bubble"><p>Vue.js</p></div>
                <div className="bubble"><p>UI/UX-Design</p></div>
                <div className="bubble"><p>React</p></div>
                <div className="bubble"><p>Software Development</p></div>
                <div className="bubble"><p>Express.js</p></div>
                <div className="bubble"><p>Python</p></div>
                <div className="bubble"><p>SpringBoot</p></div>
            </div>
        </section>
    );
}

export default Skills;