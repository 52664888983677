import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

function MobileMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef<HTMLDivElement>(null);
    const linksRef = useRef<(HTMLLIElement | null)[]>([]);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
        event.preventDefault(); // Verhindert das Standardverhalten des Links
        setMenuOpen(false); // Schließt das Menü
        // Scrollt zur gewünschten Sektion
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const tl = gsap.timeline();

        if (menuOpen) {
            // Navbar slide-in animation
            tl.to(navbarRef.current, {
                duration: 0.5,
                x: 0,
                opacity: 1,
                ease: 'power3.out',
                pointerEvents: 'auto',
            });

            // Link fade-in animations with stagger
            tl.fromTo(
                linksRef.current.filter(link => link !== null),
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    duration: 0.3,
                    opacity: 1,
                    y: 0,
                    stagger: 0.1,
                    ease: 'power3.out',
                },
                '-=0.3'
            );
        } else {
            // Reverse animation
            tl.to(linksRef.current.filter(link => link !== null), {
                duration: 0.3,
                opacity: 0,
                y: 20,
                stagger: 0.05,
                ease: 'power3.in',
            }).to(
                navbarRef.current,
                {
                    duration: 0.5,
                    x: '100%',
                    opacity: 0,
                    ease: 'power3.in',
                    pointerEvents: 'none',
                },
                '-=0.3'
            );
        }
    }, [menuOpen]);

    return (
        <>
            <div
                className={`mobile-menu-icon ${menuOpen ? 'change' : ''}`}
                onClick={handleMenuClick}
            >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div
                className="mobile-navbar"
                ref={navbarRef}
                style={{ transform: 'translateX(100%)', opacity: 0 }}
            >
                <ul>
                    <li ref={el => (linksRef.current[0] = el)}>
                        <a href="#home" onClick={(e) => handleLinkClick(e, 'home')}>Home</a>
                    </li>
                    <li ref={el => (linksRef.current[1] = el)}>
                        <a href="#about" onClick={(e) => handleLinkClick(e, 'about')}>About</a>
                    </li>
                    <li ref={el => (linksRef.current[2] = el)}>
                        <a href="#skills" onClick={(e) => handleLinkClick(e, 'skills')}>Skills</a>
                    </li>
                    {/*<li ref={el => (linksRef.current[3] = el)}>
                        <a href="#experience" onClick={(e) => handleLinkClick(e, 'experience')}>Experience</a>
                    </li>*/}
                    <li ref={el => (linksRef.current[4] = el)}>
                        <a href="#contact" onClick={(e) => handleLinkClick(e, 'contact')}>Contact</a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default MobileMenu;
